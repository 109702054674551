import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import PrettyAddressSelect from "../delivery2/tools/PrettyAddressSelect";
import PrettyCitySelect from "../delivery2/tools/PrettyCitySelect";
import RootLandingComponent from "../landing/RootLandingComponent";
import ImlAPI from "../delivery/api/ImlAPI";

export default function DevApp(props) {


    return (
        <Wrapper>

            <div>
                <span onClick={async () => {
                    let pld = await ImlAPI.getPriceDoorToDoor('Москва', 'Краснодар');
                    console.log('-->> pld = ', pld);
                }}>
                    get price
                </span>
            </div>

            {/*<RootLandingComponent/>*/}


            {/*<PrettyAddressSelect code={472} />*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;
