import axios from 'axios'

const MARGIN = 50;
const TAX = 0.92;


const BoxberryAPI = {

    async getWWPrice(from, to, weight) {
        try {
            let pld = (await axios.get(`https://delivery.paykeeper.ru/cse/boxberry/calc`, {
                params: {
                    from_code: from,
                    to_code: to,
                    weight: weight
                }
            })).data.result;
            console.log('getWWPrice: pld = ', pld);
            let price = pld.price;
            if (price == undefined) {
                return undefined;
            }
            let customer_price = Math.ceil((+price + MARGIN) / TAX);
            return {
                price: customer_price,
                period: pld.delivery_period
            };
        } catch (exc) {
            return undefined;
        }
    }

}

export default BoxberryAPI