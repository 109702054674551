import axios from 'axios'

const CseAPI = {

    async getPriceObj(from, to, weight) {
        let pld = (await axios.get(`https://delivery.paykeeper.ru/cse/price/by_cdek_codes`, {
            params: {
                from_code: from,
                to_code: to,
                weight: weight
            }
        })).data;
        return pld;
    },

    async getCityNameByCode(code) {
        let pld = (await axios.get(`https://delivery.paykeeper.ru/api2/cdek/search/city/code?code=${code}`)).data;
        return pld.cityName;
    },

    async getPriceDoorToDoorByNames(firstCityName, secondCityName, weight) {
        // fromCity, toCity, weight, volume = 1
        let pld = (await axios.get(`https://delivery.paykeeper.ru/auth/iml/calc/door-door`, {
            params: {
                fromCity: firstCityName,
                toCity: secondCityName,
                weight: weight,
                volume: 1
            }
        })).data;
        return pld;
    },

    async getPriceDoorToDoor(fromCode, toCode, weight = 1) {
        console.log('getPriceDoorToDoor occured! fromCode, toCode = ', fromCode, toCode);
        let firstCityName = await this.getCityNameByCode(fromCode);
        let secondCityName = await this.getCityNameByCode(toCode);
        let pld = await this.getPriceDoorToDoorByNames(firstCityName, secondCityName, weight);
        let d = pld.result;
        if (d != undefined && d.Price != undefined) {
            d.price = Math.ceil((d.Price + 120) / 0.94);
        }
        return d;
    },


}

export default CseAPI