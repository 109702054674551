import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

// import cdekImg from '../../../assets/images/cdek_logo.svg'
import cdekImg from '../../../assets/images/cdek_logo.svg'
import cseImg from '../../../assets/images/cse_transp.png'
import yandexImg from '../../../assets/images/yandex_logo.svg'

export default function FeaturesSection(props) {

    return (
        <Wrapper>

            <InnerPlaceholder>
                <LeftHeading>
                    Наши партнеры
                </LeftHeading>
                <WhiteBox>
                    <PartnerImg src={cdekImg}/>
                    <PartnerImg src={cseImg} style={{height: 75}} />
                    <PartnerImg src={yandexImg}/>
                </WhiteBox>
            </InnerPlaceholder>
        </Wrapper>
    );
}

const breakWidth = 1200;

const PartnerImg = styled.img`
  height: 80px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  margin-top: 80px;
  padding-bottom: 40px;
`;


const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  @media(max-width: ${breakWidth}px){
    width: 100%;
  }
`;

const LeftHeading = styled.div`
  color: #292828;
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: bold;
`;

const WhiteBox = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;