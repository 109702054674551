import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import CalculatorPanel from "../../delivery/calculator/CalculatorPanel";


export default function CalculatorSection(props) {

    return (
        <Wrapper>

            <InnerPlaceholder>
                <LeftHeading>
                    Калькулятор доставки
                </LeftHeading>
                <SubLeftHeader>
                    Узнать цены на различные направления можно с помощью нашего калькулятора.
                </SubLeftHeader>

                <CalculatorPlaceholder>
                    <CalculatorPanel/>
                </CalculatorPlaceholder>

            </InnerPlaceholder>


        </Wrapper>
    );
}

const CalculatorPlaceholder = styled.div`

`;

const breakWidth = 1200;


const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  margin-top: 80px;
  padding-bottom: 40px;
`;


const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const LeftHeading = styled.div`
  color: #292828;
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const SubLeftHeader = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 40px;
`;