import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import DeliveryAPI from "../api/DeliveryAPI";
import PrettyCitySelect from "../tools/PrettyCitySelect";

import ReactSlider from 'react-slider'
import TaxiAPI from "../../delivery2/api/TaxiAPI";

import moment from 'moment'

import cImg from '../../../assets/images/system-uicons_reverse.svg'

import calcBoxberry from '../../../assets/images/boxberry_mini.png';
import calcCdek from '../../../assets/images/calc_cdek.png';
import calcCse from '../../../assets/images/cse_logo_square.png';
import calcIml from '../../../assets/images/calc_iml.png';
import calcYandex from '../../../assets/images/calc_yandex.png';
import calcCargo from '../../../assets/images/calc_cargo.svg';

import './style.css'
import ImlAPI from "../api/ImlAPI";
import CseAPI from "../api/CseAPI";
import BoxberryAPI from "../api/BoxberryAPI";

const hasAnyBadNumber = arr => {
    return arr.reduce((f, a) => ((a == undefined) || (`${a}`.trim() == '') || window.isNaN(a) || f), false);
}
const ie = a => (a == undefined || `${a}`.trim() == '');

const voidRadioImg = require('../../../assets/images/oval.svg');
const radioCheckedImg = require('../../../assets/images/radio_checked.svg');


export function gup(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    let regexS = "[\\?&]" + name + "=([^&#]*)";
    let regex = new RegExp(regexS);
    let results = regex.exec(url);
    return results == null ? null : results[1];
}

const renderCheck = (selected, onClick = () => {
}) => {
    let src = (selected == true) ? radioCheckedImg : voidRadioImg;
    return <RadioImg src={src}
                     selected={selected}
                     onClick={() => {
                         onClick();
                     }}
    />;
}

const getPrice = (deliveryData, fromType, toType, yandexPricePld) => {
    if (deliveryData == undefined) {
        return undefined;
    }
    const priceKey = (window.pk_del == '1') ? 'price_for_paykeeper' : 'price_for_customer';
    let fK = {
        'door-door': 'd',
        'warehouse-door': 'wd',
        'door-warehouse': 'w',
        'warehouse-warehouse': 'ww'
    }[`${fromType}-${toType}`];
    if (fK == undefined) {
        return undefined;
    }
    let a = deliveryData[fK];
    if (a == undefined) {
        return undefined;
    }
    let s = a[priceKey];
    let yandexPrice = (yandexPricePld == undefined) ? undefined : +yandexPricePld.streetPrice;
    let result = undefined;
    if (s != undefined) {
        if (yandexPrice != undefined && +yandexPrice < +s && fromType == 'door' && toType == 'door') {
            result = (+yandexPrice).toFixed(2);
        } else {
            result = (+s).toFixed(2);
        }
    }
    return result;
};

const shouldUseYandex = (fromCode, toCode) => {
    if (fromCode == undefined || toCode == undefined) {
        return false;
    }
    if (fromCode != toCode) {
        return false;
    }
    const bigCities = [44, 137];
    if (bigCities.indexOf(+fromCode) > -1 || bigCities.indexOf(+toCode) > -1) {
        return false;
    }
    return true;
}

export default function CalculatorPanel(props) {
    const [formData, setFormData] = useState({
        from_code: 44,
        to_code: 44,

        width: 10,
        height: 10,
        length: 10,
        weight: 0.5,

        to_time_start: '09:00',
        to_time_end: '18:00',

        to_pvz_code: undefined,

        order_type: 'warehouse-door',
        to_date_timestamp: +new Date() + 86400000

    });

    const [from_street, setFrom_street] = useState('');
    const [to_street, setTo_street] = useState('');

    const [from_building, setFrom_building] = useState('');
    const [to_building, setTo_building] = useState('');

    const [fromType, setFromType] = useState('warehouse');
    const [toType, setToType] = useState('warehouse');

    const [deliveryData, setDeliveryData] = useState(undefined);
    const [searching, setSearching] = useState(false);
    const [redFields, setRedFields] = useState([]);

    const [yandexPricePld, setYandexPricePld] = useState(undefined);
    const [imlPld, setImlPld] = useState(undefined);

    const [csePld, setCsePld] = useState(undefined);
    const [boxberryPld, setBoxberryPld] = useState(undefined);

    let needYandex = shouldUseYandex(formData.from_code, formData.to_code);
    let hasExtraInputs = ((needYandex == true) && (fromType == 'door') && (toType == 'door'));

    const calc = useCallback(({
                                  weight, width, length, height, from_code, to_code,
                                  fromType, toType,
                                  to_date, from_street, to_street, from_building, to_building
                              }) => {
        setDeliveryData(undefined);
        if (hasAnyBadNumber([weight, width, height, length, from_code, to_code])) {
            return;
        }
        setSearching(true);
        setDeliveryData(undefined);
        setYandexPricePld(undefined);
        DeliveryAPI.getPrice({
            // to_code, from_code, weight, width, height, length, to_date, paykeeper_id: gup('pkId') || '191205-053-1'
            // to_code, from_code, weight, width, height, length, to_date, paykeeper_id: gup('pkId')
            to_code, from_code, weight, width, height, length, to_date, paykeeper_id: '200213-037-12'
        }).then(pld => {
            setDeliveryData(pld);
            let needYandex = shouldUseYandex(from_code, to_code);
            let hasExtraInputs = ((needYandex == true) && (fromType == 'door') && (toType == 'door'));
            console.log('-> hasExtraInputs: ', hasExtraInputs);
            console.log('-> needYandex: ', needYandex);
            console.log('-> fromType, toType: ', fromType, toType);
            if (hasExtraInputs == true) {
                TaxiAPI.getPriceByAddresses({
                    ...formData,
                    to_code: to_code,
                    from_code: from_code,
                    code: from_code,

                    from_street,
                    from_building,
                    to_street,
                    to_building
                }).then(xPld => {
                    console.log('xPld = ', xPld);
                    setYandexPricePld(xPld);
                    setSearching(false);
                }).catch(exc => {

                });
            } else {
                setSearching(false);
            }
            setImlPld(undefined);
            setBoxberryPld(undefined);
            setCsePld(undefined);

            CseAPI.getPriceObj(from_code, to_code, weight).then(csePld => {
                setCsePld(csePld);
            }).catch(exc => {
                console.log('cse api: exc = ', exc);
            });


            BoxberryAPI.getWWPrice(from_code, to_code, weight).then(boxPld => {
                setBoxberryPld(boxPld);
            }).catch(exc => {

            });

            ImlAPI.getPriceDoorToDoor(from_code, to_code, weight).then(iPld => {
                console.log('got iml pld = ', iPld);
                setImlPld(iPld);
            }).catch(exc => {
                console.log('iml exception: exc = ', exc);
            })
        });
    }, []);

    // useEffect(() => {
    //   calc({...formData, to_date: moment(+formData.to_date_timestamp).format('YYYY-MM-DD')});
    // }, [formData.from_code, formData.to_code, formData.width, formData.height, formData.length, formData.weight]);

    let resData = (deliveryData == undefined) ? undefined : (formData.order_type == 'warehouse-door' ? deliveryData.d : deliveryData.w);

    const price = (resData == undefined || resData.customer == undefined) ? undefined : +resData.customer.price;

    let error = (deliveryData == undefined || deliveryData.error == undefined) ? undefined : deliveryData.error;
    if (error == undefined && (window.isNaN(formData.weight) || +getTotalWeight(formData.width, formData.height, formData.length, formData.weight) > 30)) {
        if (getVolWeight(formData.width, formData.height, formData.length, formData.weight) > 30) {
            error = {message: 'Невозможно осуществить доставку по этому направлению при заданных условиях. Груз слишком габаритный.'};
        } else {
            error = {message: 'Невозможно осуществить доставку по этому направлению при заданных условиях. Вес превышает 30 кг.'};
        }
    }

    // console.log('render: searching = ', searching);
    let durationMessage = ``;
    if (resData != undefined && resData.delivery_period_min != undefined) {
        durationMessage = `${resData.delivery_period_min} - ${resData.delivery_period_max} дн.`;
        if (resData.delivery_period_min == resData.delivery_period_max) {
            durationMessage = `${resData.delivery_period_min} дн.`;
        }
    }
    let cdekDurationMessage = durationMessage;
    let validationMessage = ``;
    if (redFields.indexOf('from_code') > -1 || redFields.indexOf('to_code') > -1) {
        validationMessage = 'Пожалуйста, укажите города отправителя и получателя.'
    }
    if (redFields.indexOf('weight') > -1 || redFields.indexOf('length') > -1 || redFields.indexOf('width') > -1 || redFields.indexOf('height') > -1) {
        validationMessage = 'Пожалуйста, заполните все необходимые поля.'
    }
    const hasResult = (deliveryData != undefined && formData.from_code != undefined && formData.to_code != undefined);
    const priceKey = (window.pk_del == '1') ? 'price_for_paykeeper' : 'price_for_customer';
    // console.log('priceKey = ', priceKey);

    const canSwitch = !(formData == undefined || formData.from_code == undefined || formData.to_code == undefined || formData.from_code == formData.to_code);

    let resPrice = getPrice(deliveryData, fromType, toType, yandexPricePld);
    let isYandexWinner = (yandexPricePld != undefined && +yandexPricePld.streetPrice == +resPrice);
    if (isYandexWinner == true) {
        durationMessage = `${yandexPricePld.minutes} минут`
    }
    let doorDoorPriceItems = [];

    if (yandexPricePld != undefined) {
        console.log('--->>> yandexPricePld = ', yandexPricePld);
        doorDoorPriceItems.push({
            label: 'Яндекс.Такси',
            price: +yandexPricePld.streetPrice,
            durationMessage: (yandexPricePld.minutes == undefined || window.isNaN(yandexPricePld.minutes)) ? undefined : `${yandexPricePld.minutes} минут`,
            vendor: 'Яндекс Такси',
            vendorImg: calcYandex,
            priceMessage: `${+yandexPricePld.streetPrice} руб.`
        });
    }
    if (deliveryData != undefined) {
        doorDoorPriceItems.push({
            label: 'СДЭК',
            price: Math.round(+deliveryData.d.price_for_customer),
            durationMessage: cdekDurationMessage,
            vendor: 'СДЭК',
            vendorImg: calcCdek,
            priceMessage: `${Math.round(+deliveryData.d.price_for_customer)} руб. | ${cdekDurationMessage}`
        });
    }

    if (csePld != undefined && csePld.d != undefined) {
        doorDoorPriceItems.push({
            label: 'CSE',
            price: +csePld.d.customer_price,
            durationMessage: cdekDurationMessage,
            vendor: 'CSE',
            vendorImg: calcCse,
            priceMessage: `${+csePld.d.customer_price} руб. | ${cdekDurationMessage}`
        });
    }

    console.log('!!!-> fromType, toType = ', fromType, toType);

    // if (imlPld != undefined && fromType == 'door' && toType == 'door') {
    //     doorDoorPriceItems.push({
    //         label: 'IML',
    //         price: +imlPld.price,
    //         durationMessage: `${-Math.ceil((+moment().startOf('day') - +moment(+new Date(imlPld.DeliveryDate))) / 86400000)} дн.`,
    //         vendor: 'IML',
    //         vendorImg: calcIml,
    //         priceMessage: `${+imlPld.price} руб. | ${-Math.ceil((+moment().startOf('day') - +moment(+new Date(imlPld.DeliveryDate))) / 86400000)} дн.`
    //     });
    // }

    doorDoorPriceItems = doorDoorPriceItems.sort((a, b) => (+a.price - +b.price));
    let isDoorDoor = (fromType == 'door' && toType == 'door');

    let xKey = {
        'warehouse-door': 'wd',
        'warehouse-warehouse': 'ww',
        'door-door': 'd',
        'door-warehouse': 'w'
    }[`${fromType}-${toType}`];
    // cdek + cse
    let xItems = [];

    if ((deliveryData != undefined) && (deliveryData[xKey] != undefined)) {
        xItems.push({
            label: 'СДЭК',
            price: +(deliveryData[xKey]).price_for_customer,
            durationMessage: cdekDurationMessage,
            vendor: 'СДЭК',
            vendorImg: calcCdek,
            priceMessage: `${Math.round(+(deliveryData[xKey]).price_for_customer)} руб. | ${cdekDurationMessage}`
        });
    }

    if ((csePld != undefined) && (csePld[xKey] != undefined)) {
        xItems.push({
            label: 'CSE',
            price: +csePld[xKey].customer_price,
            durationMessage: cdekDurationMessage,
            vendor: 'CSE',
            vendorImg: calcCse,
            priceMessage: `${+csePld[xKey].customer_price} руб. | ${cdekDurationMessage}`
        });
    }

    if ((boxberryPld != undefined) && (xKey == 'ww')) {
        console.log('render: boxberryPld = ', boxberryPld);
        xItems.push({
            label: 'Boxberry',
            price: +boxberryPld.price,
            durationMessage: `${boxberryPld.period} д`,
            vendor: 'Boxberry',
            vendorImg: calcBoxberry,
            priceMessage: `${+boxberryPld.price} руб. | ${boxberryPld.period} д`
        });
    }

    xItems = xItems.sort((a, b) => (+a.price - +b.price));

    console.log('xItems = ', xItems);


    return (
        <Wrapper>

            {/*<MainHeading>*/}
            {/*    РАСЧЁТ СТОИМОСТИ ДОСТАВКИ*/}
            {/*</MainHeading>*/}

            <SubMainHeading>
                1. Маршрут
            </SubMainHeading>

            <TopFromToSection>
                <TopFromToSide>
                    <Label>
                        Откуда:
                    </Label>
                    <PrettyCitySelect red={redFields.indexOf('from_code') > -1} placeholder={''}
                                      className={'calculator_pretty_select'}
                                      code={formData.from_code}
                                      onChange={newCode => {
                                          setFormData({...formData, from_code: newCode, order_type: 'warehouse-door'});
                                          setRedFields(redFields.filter(a => (a != 'from_code')));
                                          setDeliveryData(undefined);
                                      }}/>

                    {hasExtraInputs == false ? null :
                        <div style={{marginTop: 10}}>
                            <MyInputPlaceholder>
                                <TripletInput placeholder={'Улица'}
                                              value={from_street}
                                              onChange={evt => {
                                                  setFrom_street(evt.target.value);
                                              }}/>
                            </MyInputPlaceholder>
                            <MyInputPlaceholder>
                                <TripletInput
                                    placeholder={'Номер дома'}
                                    value={from_building}
                                    onChange={evt => {
                                        setFrom_building(evt.target.value);
                                    }}
                                />
                            </MyInputPlaceholder>
                        </div>
                    }

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 19}}>
                        <TypeRow>
                            {renderCheck(fromType == 'warehouse', () => {
                                setFromType('warehouse');
                            })}
                            <RowNameSpan selected={(fromType == 'warehouse')} onClick={() => {
                                setFromType('warehouse');
                                if (needYandex == true) {
                                    setDeliveryData(undefined);
                                }
                            }}>
                                От пункта выдачи
                            </RowNameSpan>
                        </TypeRow>

                        <TypeRow>
                            {renderCheck(fromType == 'door', () => {
                                setFromType('door');
                            })}
                            <RowNameSpan selected={(fromType == 'door')} onClick={() => {
                                setFromType('door');
                                if (needYandex == true) {
                                    setDeliveryData(undefined);
                                }
                            }}>
                                От двери
                            </RowNameSpan>
                        </TypeRow>
                    </div>

                </TopFromToSide>

                <TopRefArrowSection>
                    <ArrowsImg src={cImg}
                               clickable={canSwitch}
                               onClick={() => {
                                   if (canSwitch == false) {
                                       return;
                                   }
                                   setFormData({
                                       ...formData,
                                       from_code: formData.to_code,
                                       to_code: formData.from_code
                                   });

                               }}
                    />
                </TopRefArrowSection>

                <TopFromToSide>
                    <Label>
                        Куда:
                    </Label>
                    <PrettyCitySelect red={redFields.indexOf('to_code') > -1} placeholder={''}
                                      className={'calculator_pretty_select'}
                                      code={formData.to_code}
                                      onChange={newCode => {
                                          setFormData({...formData, to_code: newCode, order_type: 'warehouse-door'});
                                          setRedFields(redFields.filter(a => (a != 'to_code')));
                                          setDeliveryData(undefined);
                                      }}/>

                    {hasExtraInputs == false ? null :
                        <div style={{marginTop: 10}}>
                            <MyInputPlaceholder>
                                <TripletInput placeholder={'Улица'}
                                              value={to_street}
                                              onChange={evt => {
                                                  setTo_street(evt.target.value);
                                              }}/>
                            </MyInputPlaceholder>
                            <MyInputPlaceholder>
                                <TripletInput
                                    placeholder={'Номер дома'}
                                    value={to_building}
                                    onChange={evt => {
                                        setTo_building(evt.target.value);
                                    }}
                                />
                            </MyInputPlaceholder>
                        </div>
                    }
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 19}}>
                        <TypeRow>
                            {renderCheck(toType == 'warehouse', () => {
                                setToType('warehouse');
                            })}
                            <RowNameSpan selected={(toType == 'warehouse')} onClick={() => {
                                setToType('warehouse');
                                if (needYandex == true) {
                                    setDeliveryData(undefined);
                                }
                            }}>
                                До пункта выдачи
                            </RowNameSpan>
                        </TypeRow>

                        <TypeRow>
                            {renderCheck(toType == 'door', () => {
                                setToType('door');
                            })}
                            <RowNameSpan selected={(toType == 'door')} onClick={() => {
                                setToType('door');
                                if (needYandex == true) {
                                    setDeliveryData(undefined);
                                }
                            }}>
                                До двери
                            </RowNameSpan>
                        </TypeRow>
                    </div>
                </TopFromToSide>
            </TopFromToSection>

            <SubMainHeading style={{marginTop: 40, borderTop: '1px solid rgb(41,40,40, 0.1)', paddingTop: 40}}>
                2. Параметры посылки
            </SubMainHeading>

            <BottomField style={{marginTop: 20}}>
                <TripletPlaceholder>
                    <TripletItem>
                        <TripletLabel>
                            Вес (кг)
                        </TripletLabel>
                        <TripletInput red={redFields.indexOf('weight') > -1} placeholder={'Вес'} value={formData.weight}
                                      onChange={evt => {
                                          setFormData({...formData, weight: evt.target.value});
                                          setRedFields(redFields.filter(a => (a != 'weight')));
                                      }}/>

                        <SliderItemPlaceholder>
                            <StyledSlider
                                value={formData.weight}
                                renderTrack={Track}
                                renderThumb={Thumb}
                                max={30.0}
                                min={0.1}
                                step={0.1}
                                invert={false}
                                onChange={a => {
                                    if (+a < 0.1) {
                                        return;
                                    }
                                    setFormData({...formData, weight: a});
                                }}
                            />
                        </SliderItemPlaceholder>
                    </TripletItem>
                    <TripletItem>
                        <TripletLabel>
                            Длина (см)
                        </TripletLabel>
                        <TripletInput red={redFields.indexOf('width') > -1} placeholder={'Длина'} value={formData.width}
                                      onChange={evt => {
                                          setFormData({...formData, width: evt.target.value});
                                          setRedFields(redFields.filter(a => (a != 'width')));
                                      }}/>
                        <SliderItemPlaceholder>
                            <StyledSlider
                                value={formData.width}
                                renderTrack={Track}
                                renderThumb={Thumb}
                                max={100}
                                min={1}
                                step={1}
                                invert={false}
                                onChange={a => {
                                    if (+a < 0.1) {
                                        return;
                                    }
                                    setFormData({...formData, width: a});
                                }}
                            />
                        </SliderItemPlaceholder>
                    </TripletItem>
                    <TripletItem>
                        <TripletLabel>
                            Ширина (см)
                        </TripletLabel>
                        <TripletInput red={redFields.indexOf('height') > -1} placeholder={'Ширина'}
                                      value={formData.height}
                                      onChange={evt => {
                                          setFormData({...formData, height: evt.target.value});
                                          setRedFields(redFields.filter(a => (a != 'height')));
                                      }}/>
                        <SliderItemPlaceholder>
                            <StyledSlider
                                value={formData.height}
                                renderTrack={Track}
                                renderThumb={Thumb}
                                max={100}
                                min={1}
                                step={1}
                                invert={false}
                                onChange={a => {
                                    if (+a < 0.1) {
                                        return;
                                    }
                                    setFormData({...formData, height: a});
                                }}
                            />
                        </SliderItemPlaceholder>
                    </TripletItem>
                    <TripletItem>
                        <TripletLabel>
                            Высота (см)
                        </TripletLabel>
                        <TripletInput red={redFields.indexOf('length') > -1} placeholder={'Высота'}
                                      value={formData.length}
                                      onChange={evt => {
                                          setFormData({...formData, length: evt.target.value});
                                          setRedFields(redFields.filter(a => (a != 'length')));
                                      }}/>
                        <SliderItemPlaceholder>
                            <StyledSlider
                                value={formData.length}
                                renderTrack={Track}
                                renderThumb={Thumb}
                                max={100}
                                min={1}
                                step={1}
                                invert={false}
                                onChange={a => {
                                    if (+a < 0.1) {
                                        return;
                                    }
                                    setFormData({...formData, length: a});
                                }}
                            />
                        </SliderItemPlaceholder>
                    </TripletItem>
                </TripletPlaceholder>
            </BottomField>

            {validationMessage.length == 0 ? null :
                <ValidationErrorPlaceholder>
                    {validationMessage}
                </ValidationErrorPlaceholder>
            }

            <BottomButtonPlaceholder>
                <SubmitButton loading={`${searching}`} onClick={() => {
                    let newRedFields = [];
                    if (formData.from_code == undefined) {
                        newRedFields.push('from_code');
                    }
                    if (formData.to_code == undefined) {
                        newRedFields.push('to_code');
                    }
                    if (formData.weight == undefined || ie(formData.weight) || window.isNaN(formData.weight)) {
                        newRedFields.push('weight');
                    }
                    if (formData.length == undefined || ie(formData.length) || window.isNaN(formData.length)) {
                        newRedFields.push('length');
                    }
                    if (formData.width == undefined || ie(formData.width) || window.isNaN(formData.width)) {
                        newRedFields.push('width');
                    }
                    if (formData.height == undefined || ie(formData.height) || window.isNaN(formData.height)) {
                        newRedFields.push('height');
                    }
                    if (newRedFields.length > 0) {
                        setRedFields(newRedFields);
                        return;
                    }
                    setRedFields(newRedFields);
                    calc({
                        ...formData,
                        fromType: fromType,
                        toType: toType,
                        from_street: from_street,
                        to_street: to_street,
                        from_building: from_building,
                        to_building: to_building,
                        to_date: getFormattedDate(formData.to_date_timestamp)
                    });
                }}>
                    Расчитать стоимость
                </SubmitButton>
            </BottomButtonPlaceholder>

            {error == undefined && hasResult == false ? null :
                <ResultPlaceholder>
                    {error != undefined ?
                        <ErrorPlaceholder>
                            {error.message}
                        </ErrorPlaceholder>
                        :
                        <div>

                            {hasResult == false ? null :
                                <PricePlaceholder>
                                    {searching == true ? <span>идет расчет стоимости...</span> :
                                        <div>
                                            {/*{isDoorDoor == true ? null :*/}
                                            {/*    <P style={{marginBottom: 10}}>*/}
                                            {/*        {`Расчетный вес всего отправления: `}*/}
                                            {/*        <b>{`${getTotalWeight(formData.height, formData.width, formData.length, formData.weight).toFixed(1)} кг`}</b>*/}
                                            {/*    </P>*/}
                                            {/*}*/}

                                            {resPrice == undefined ? 'Доставка при указанных условиях не доступна' :
                                                <React.Fragment>
                                                    {isDoorDoor == false ?
                                                        <React.Fragment>
                                                            {renderItems(
                                                                getTotalWeight(formData.height, formData.width, formData.length, formData.weight),
                                                                xItems
                                                            )}
                                                        </React.Fragment>

                                                        :
                                                        <React.Fragment>


                                                            {renderItems(
                                                                getTotalWeight(formData.height, formData.width, formData.length, formData.weight),
                                                                doorDoorPriceItems
                                                            )}

                                                            {/*{doorDoorPriceItems.map((a, i) => {*/}
                                                            {/*    return (*/}
                                                            {/*        <P key={i} style={{marginBottom: 15}}>*/}
                                                            {/*            <div style={{*/}
                                                            {/*                fontWeight: 'bold',*/}
                                                            {/*                fontSize: 16,*/}
                                                            {/*                marginBottom: 5*/}
                                                            {/*            }}>*/}
                                                            {/*                {a.vendor}*/}
                                                            {/*            </div>*/}
                                                            {/*            <div>*/}
                                                            {/*                Стоимость: <b>{`${Math.round(+a.price)} руб.`}</b> {(a.durationMessage == undefined) ? '' : ` | Время доставки: ${a.durationMessage}`}*/}
                                                            {/*            </div>*/}
                                                            {/*        </P>*/}
                                                            {/*    )*/}
                                                            {/*})}*/}
                                                        </React.Fragment>
                                                    }

                                                </React.Fragment>
                                            }

                                            {(durationMessage == '' || isDoorDoor == true) ? null :
                                                <DurationPlaceholder>
                                                    {`Время доставки: ${durationMessage}`}
                                                </DurationPlaceholder>
                                            }

                                        </div>
                                    }

                                </PricePlaceholder>
                            }

                        </div>
                    }
                </ResultPlaceholder>
            }


        </Wrapper>
    );
}

const renderBox = props => {
    const {
        label, priceMessage, duration, vendorImg
    } = props;
    return (
        <VendorBox>
            <VendorBoxLeft>
                <VendorImg src={vendorImg}/>
            </VendorBoxLeft>
            <VendorBoxRight>
                <VendorBoxLabel>
                    {label}
                </VendorBoxLabel>
                <VendorBoxInfo>
                    {priceMessage}
                </VendorBoxInfo>
            </VendorBoxRight>
        </VendorBox>
    )
}

const renderItems = (weight, items = []) => {
    return (
        <BoxesListPlaceholder>
            <BoxItemPlaceholder>
                {renderBox({
                    vendorImg: calcCargo,
                    label: 'Расчетный вес',
                    priceMessage: `${weight.toFixed(1)} кг`
                })}
            </BoxItemPlaceholder>
            {items.map((it, i) => {
                return (
                    <BoxItemPlaceholder key={i}>
                        {renderBox({
                            ...it,
                        })}
                    </BoxItemPlaceholder>
                )
            })}

        </BoxesListPlaceholder>
    )
}

const BoxItemPlaceholder = styled.div`
  margin-right: 30px;
`;

const BoxesListPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VendorBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VendorBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 10px;
`;

const VendorBoxLabel = styled.div`

`;

const VendorBoxInfo = styled.div`
  font-weight: bold;
`;

const VendorImg = styled.img`

`;

const VendorBoxRight = styled.div`

`;

const MyInputPlaceholder = styled.div`
  margin-bottom: 10px;
`;

const MyInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
  outline: none;
`;

const SliderItemPlaceholder = styled.div`
  margin-top: 10px;
`;

const TypeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  :first-of-type {
    margin-right: 20px;
  }
`;

const RowNameSpan = styled.div`
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #000000;
  font-weight: normal;
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const RadioImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const ArrowsImg = styled.img`
  height: 57px;
  cursor: ${props => (props.clickable == true ? 'pointer' : 'defaults')};

  :hover {
    opacity: ${props => (props.clickable == true ? 0.8 : 1)};
  }
`;

const TopFromToSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const TopFromToSide = styled.div`
  flex: 1;
`;

const TopRefArrowSection = styled.div`
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0px 5.54444px 27.7222px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
  font-family: "PT Sans";
  padding-left: 50px;
  padding-right: 50px;
`;

const BottomButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 32px;
  margin-bottom: 0px;
`;

const SubmitButton = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fc0 !important;
  border-radius: 0;
  padding: 12px 35px;
  font-size: 18px;
  line-height: 23px;

  color: #000000 !important;
  float: none;
  font-weight: normal;
  border: 0;
  opacity: ${props => (props.loading == 'true' ? 0.5 : 1)};
  cursor: ${props => (props.loading == 'true' ? 'default' : 'pointer')};
`;

const ResultPlaceholder = styled.div`
  border-top: 1px solid rgba(0, 0, 0, .1);
  margin-top: 20px;
  padding-top: 10px;
`;

const PriceValuePlaceholder = styled.div`
  //font-size: 36px;
  font-size: 32px;
  margin-bottom: 10px;
`;

const Field = styled.div`

`;

const Label = styled.div`
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 4px;
  color: #8F8F8F;
  font-weight: normal;
`;


const TripletPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const TripletItem = styled.div`
  flex: 1;
  margin-left: 17px;
  margin-right: 17px;

  :first-of-type {
    margin-left: 0px;
  }

  :last-of-type {
    margin-right: 0px;
  }
`;

const TripletDelimiter = styled.div`
  width: 40px;
  text-align: center;
`;


const FLeft = styled.div`
  width: 170px;
`;

const FRight = styled.div`
  flex: 1;
`;

const TopField = styled.div`
  margin-bottom: 10px;
  font-weight: 300;
`;

const BottomField = styled.div`
  margin-top: 35px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DurationPlaceholder = styled.div`
  font-size: 15px;
  line-height: 21px;
  font-weight: 300;
`;

const PricePlaceholder = styled.div`
  margin-top: 20px;
  color: #3d3d3d;
  font-size: 15px;
  line-height: 21px;
`;

const SubMainHeading = styled.div`
  color: #292828;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  margin-bottom: 20px;
`;

const MainHeading = styled.div`
  color: #ae1b01;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 21px;
  margin-top: 0;
  margin-bottom: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const TripletLabel = styled.div`
  font-size: 16px;
  color: #8F8F8F;
  line-height: 21px;
  font-family: "PT Sans";
  font-weight: normal;
`;

const H2 = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 15px;
  color: #3d3d3d;
  font-weight: 700;
`;

const P = styled.div`
  font-size: 15px;
  line-height: 21px;
  font-weight: 300;
`;

const TripletInput = styled.input`
  border: 1px solid ${props => (props.red == true ? 'red' : '#ced4da')};
  outline: none;
  width: 100%;
  color: #000000;
  box-sizing: border-box;

  padding-left: 16px;

  height: 39px;
  line-height: 39px;

  font-size: 18px;
  border-radius: 3px;

  :focus {
    color: #495057;
    background-color: #fff;
    border-color: ${props => (props.red == true ? 'red' : '#80bdff')};
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${props => (props.bad == true ? 'rgba(255, 0, 0, 0.25)' : 'rgba(0, 123, 255, 0.25)')};
  }
`;

const ErrorPlaceholder = styled.div`
  color: #3d3d3d;
  font-weight: 700;
  font-size: 15px;
`;

const ValidationErrorPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  color: red;
  font-size: 15px;
  line-height: 21px;
`;

const Ul = styled.ul`
  list-style: none;
  margin-top: 10px;
  padding-left: 0px;
  padding-left: 0px;
`;

const Li = styled.li`
  font-size: 15px;
  line-height: 21px;
  font-weight: 300;

  :before {
    position: absolute;
    content: '';
    width: 11px;
    height: 1px;
    background-color: #000;
    left: -19px;
    top: 12px;
  }
`;


const sliderHeight = 10;
const thumbHeight = sliderHeight * 2.4;


const StyledSlider = styled(ReactSlider)`
  height: ${sliderHeight}px;
`;


const StyledThumb = styled.div`
  margin-top: ${-((thumbHeight - sliderHeight) / 2.0)}px;
  width: ${thumbHeight}px;
  height: ${thumbHeight}px;
  line-height: ${thumbHeight}px;
  height: ${thumbHeight}px;
  text-align: center;
  //background-color: #000;
  background-color: #FFFFFF;
  color: #fff;
  border-radius: 50%;
  border: 1px solid #ced4da;
  cursor: grab;
  font-size: ${thumbHeight * 0.5}px;
  box-sizing: border-box;
`;

const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>;

const StyledTrack = styled.div`
  background: grey;
  top: 0;
  bottom: 0;
  background: ${props => props.index === 2 ? '#f00' : props.index === 1 ? '#959597' : '#fc0'};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index}/>;


const getTotalWeight = (width, height, length, weight) => {
    const hasAnyBadNumber = arr => {
        return arr.reduce((f, a) => ((a == undefined) || (`${a}`.trim() == '') || window.isNaN(a) || f), false);
    }
    if (hasAnyBadNumber([weight, height, length, width]) == true) {
        return 0;
    }
    const vWeight = ((+width) * (+height) * (+length)) / 5000.0;
    let totalWeight = Math.max(+weight, +vWeight);
    return totalWeight;
}

const getVolWeight = (width, height, length, weight) => {
    const hasAnyBadNumber = arr => {
        return arr.reduce((f, a) => ((a == undefined) || (`${a}`.trim() == '') || window.isNaN(a) || f), false);
    }
    if (hasAnyBadNumber([weight, height, length, width]) == true) {
        return 0;
    }
    const vWeight = ((+width) * (+height) * (+length)) / 5000.0;
    return vWeight;
}

const getFormattedDate = (t) => {
    let today = new Date(t);
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    let s = `${yyyy}-${mm}-${dd}`;
    return s;
}
