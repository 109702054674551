import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TopSection from "./sections/TopSection";
import FeaturesSection from "./sections/FeaturesSection";
import PartnersSection from "./sections/PartnersSection";
import CalculatorSection from "./sections/CalculatorSection";
import ScreensSection from "./sections/ScreensSection";
import NewLeadSection from "./sections/NewLeadSection";
import NewLeadSection2 from "./sections/NewLeadSection2";
import FooterSection from "./sections/FooterSection";
import MapMarkerSection from "./sections/MapMarkersSection";

export default function RootLandingComponent(props) {


    return (
        <Wrapper>
            <TopSection/>

            <div id={'features_section'}>
                <FeaturesSection/>
            </div>

            <div id={'calculator_section'}>
                <CalculatorSection/>
            </div>

            <div id={'lead_section_0'}>
                <NewLeadSection2/>
            </div>

            <ScreensSection/>

            <PartnersSection/>

            <MapMarkerSection/>

            <div id={'new_lead_section'}>
                <NewLeadSection/>
            </div>

            <FooterSection/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  font-family: "PT Sans";
  background: #E5E5E5;
  overflow-x: hidden !important;
`;

