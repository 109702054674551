import axios from 'axios'

// const API_ENDPOINT = 'https://pk-del.sabir.pro/api'
const API_ENDPOINT = 'https://delivery.paykeeper.ru/api2'
const MAX_DROPDOWN_RESULTS_VALUE = 50;

const DeliveryAPI = {


    async getPrice(data) {
        console.log('getPrice: data = ', data);
        let pld = (await axios.get(`${API_ENDPOINT}/calc/price/formatted`, {
            params: data
        }, {auth: this.auth})).data
        // console.log('getPrice occured! pld = ', pld)
        return pld
    },
    async getCityByCode(code) {
        if (code == undefined || code == '') {
            return undefined;
        }
        return (await axios.get(`${API_ENDPOINT}/cdek/search/city/code`, {params: {code: code}})).data;
    },
    async getDropdownCityOptions(city) {
        if (city == undefined || city == '') {
            return [];
        }
        return (await axios.get(`${API_ENDPOINT}/cdek/search/city_options/cache`,
            {params: {city: city, maxValue: MAX_DROPDOWN_RESULTS_VALUE}})).data;
    },
    async getPvz(code) {
        return (await axios.get(`${API_ENDPOINT}/city/${code}/pvz`)).data;
    },
    async getCdekMonitoringItems() {
        return (await axios.get(`${API_ENDPOINT}/monitoring/cdek`)).data.sort((a, b) => (+b.minutes - +a.minutes));
    }

}

const allItemsAreEmpty = arr => {
    return arr.reduce((f, a) => (((a == undefined) || (`${a}`.trim() == '')) && f), true);
}

export default DeliveryAPI
