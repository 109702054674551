import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import logo from '../../../assets/images/pk_del_logo.svg'
import truck_russia from '../../../assets/images/truck_russia.svg'

import zoom_image from '../../../assets/images/zoom_icon_q.svg'
import calculator_image from '../../../assets/images/calculator.svg'
import box_image from '../../../assets/images/box_m.svg'
import axios from "axios";
import {DELIVERY_API_ENDPOINT} from "../../delivery2/api/DeliveryAPI";

const ITEMS = [
    {
        label: 'Калькулятор',
        name: 'calculator',
        linkId: 'calculator_section'
    },
    {
        label: 'Преимущества',
        name: 'advances',
        linkId: 'features_section'
    },
    // {
    //     label: 'Виджеты',
    //     name: 'widgets'
    // },
    {
        label: 'Подключение',
        name: 'onboarding',
        linkId: 'new_lead_section'
    },
];

const scrollToDiv = (divId) => {
    try {
        let element = document.getElementById(divId);
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    } catch (exc) {

    }
}

const BADGES = [
    {
        label: 'Подключить доставку',
        image: box_image,
        name: 'order',
        onClick: () => {
            scrollToDiv('lead_section_0');
        }
    },
    {
        label: 'Расчитать стоимость',
        image: calculator_image,
        name: 'calculator',
        onClick: () => {
            scrollToDiv('calculator_section');
        }
    },
    {
        label: 'Отследить посылку',
        image: zoom_image,
        name: 'track',
        onClick: () => {

        }
    },

]

export default function TopSection(props) {


    return (
        <Wrapper>
            <TopHeader>
                <TopHeaderInner>
                    <TopLeft>
                        <Logo src={logo}/>
                        <TopLinksPlaceholder>
                            {ITEMS.map((x, i) => {
                                return (
                                    <TopLinkItem onClick={() => {
                                        if (x.linkId == undefined) {
                                            return;
                                        }
                                        let element = document.getElementById(x.linkId);
                                        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                                    }}>
                                        {x.label}
                                    </TopLinkItem>
                                )
                            })}
                        </TopLinksPlaceholder>
                    </TopLeft>

                    <TopRight>
                        <div>
                            <PhonePlaceholder>
                                8 (800) 775-37-51
                            </PhonePlaceholder>
                            <PhoneInfoPlaceholder>
                                Контакт центр
                            </PhoneInfoPlaceholder>
                        </div>

                        <DemoButton style={{marginLeft: 40}} onClick={() => {
                            window.location.href = '/#/demo';
                        }}>
                            Демо версия
                        </DemoButton>

                    </TopRight>

                </TopHeaderInner>
            </TopHeader>

            <div style={{background: '#CC1626', height: 450}}>
                <ContPlaceholder>
                    <ContSubShadowPlaceholder></ContSubShadowPlaceholder>
                    <ContSubPlaceholder>
                        <ContentInnerPlaceholder>
                            <ContLeft>
                                <MainMotto>
                                    Курьерская доставка <br/>
                                    от платформы PayKeeper
                                </MainMotto>
                                <SubMotto>
                                    отправки по любым направлениям внутри России
                                </SubMotto>
                            </ContLeft>
                            <ContRight>
                                <CarMapImg src={truck_russia}/>
                            </ContRight>
                        </ContentInnerPlaceholder>
                    </ContSubPlaceholder>
                </ContPlaceholder>
            </div>


            <BadgesPlaceholder>
                {BADGES.map((x, i) => {
                    return (
                        <Badge key={i} onClick={() => {
                            x.onClick();
                        }}>
                            <BadgeImage src={x.image}/>
                            <LabelSpan>
                                {x.label}
                            </LabelSpan>
                        </Badge>
                    )
                })}
            </BadgesPlaceholder>

        </Wrapper>
    );
}

async function getHistory(data) {
    console.log('getPrice: data = ', data);
    let pld = (await axios.get(`${DELIVERY_API_ENDPOINT}/calc/price/formatted`, {
        params: data
    }, {auth: this.auth})).data
    return pld
}

const LabelSpan = styled.div`
  color: #292828;
  opacity: 0.5;
  margin-left: 20px;
  font-weight: bold;
  font-size: 16px;
`;

const BadgesPlaceholder = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

const Badge = styled.div`
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  filter: drop-shadow(0px 10px 26px rgba(41, 40, 40, 0.1));
  border-radius: 6px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: flex-start;
  cursor: pointer;

  :hover {

  }
`;

const BadgeImage = styled.img`
  width: 30px;
  height: 30px;
`;


const breakWidth = 1200;

const CarMapImg = styled.img`
  height: 100%;
`;

const MainMotto = styled.div`
  color: white;
  font-size: 48px;
  line-height: 65px;
  font-weight: bold;
`;

const SubMotto = styled.div`
  color: #FFFFFF;
  font-size: 18px;
`;

const ContentInnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  height: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const ContLeft = styled.div`

`;

const ContRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const ContSubPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
`;
const ContSubShadowPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background: radial-gradient(50% 50% at 50% 50%, rgba(41, 40, 40, 0) 0%, #292828 100%);
  opacity: 0.15;
`;


const ContPlaceholder = styled.div`
  height: 450px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

const DemoButton = styled.div`
  background: #CC1626;
  border-radius: 5px;
  height: 55px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const PhonePlaceholder = styled.div`
  color: #292828;
  font-weight: bold;
  font-size: 18px;
`;

const PhoneInfoPlaceholder = styled.div`
  font-size: 14px;
  color: #292828;
  opacity: 0.5;
  text-align: right;
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const TopLinksPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const TopLinkItem = styled.div`
  color: #292828;
  font-size: 16px;
  cursor: pointer;
  margin-right: 35px;
`;

const Logo = styled.img`
  height: 32px;
  margin-right: 40px;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100vw;
  position: relative;
  padding-bottom: 45px;
`;

const TopHeader = styled.div`
  background: white;
  height: 90px;
`;

const TopHeaderInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;